import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import logo from '../res/logo.png';
import { AccountCircle } from '@mui/icons-material';

const TopBar = ({ title, userData }) => {
  return (
    <AppBar
      color="inherit"
      position="fixed"
      style={{ background: '#bbb', flexDirection: "row", alignItems: "center" }}
    >
      <Toolbar style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
        <img src={logo} height={32} style={{ marginRight: 16 }} alt="KUKA" />
        <Typography variant="h4" component="div" sx={{ flexGrow: 1 }}>
          {title || ''}
        </Typography>
        {userData.ems && <>
          <Typography variant='h5'>
            {userData.ems}, {userData.region}
          </Typography>
          <IconButton
            size="large"
            color="inherit"
          >
            <AccountCircle fontSize='large'/>
          </IconButton>
        </>
        }
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  title: PropTypes.string,
  userData: PropTypes.object.isRequired,
};

export default TopBar;