import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button,
  Card, CardContent, CardHeader, 
  CircularProgress} from '@mui/material';
import { FileUpload, Logout } from '@mui/icons-material';
import { withStyles } from '@mui/styles';
import { authenticate, uploadPdfFile } from './api';
import Feedback from './components/Feedback';
import TopBar from './components/TopBar';
import { get_cookies } from './utils';
import PropTypes from 'prop-types';

const styles = {
  app: {
    display: 'flex',
    flex: 1,
  },
  content: {
    marginTop: 64,
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    backgroundColor: '#eee',
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center'
  },
};


function App({ classes }) {
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState([]);
  const [emsNumInput, setEmsNumInput] = useState("");
  let inputRef = useRef();
  const [authenticated, setAuthenticated] = useState(false);
  const [userData, setUserData] = useState({
    ems: "",
    region: "",
  });

  useEffect(() => {
    if(get_cookies()?.session) {
      setAuthenticated(true);
    }
  });

  const handleFeedbackClose = () => setFeedback(null);

  const startUpload = async () => {
    if(get_cookies()?.session) inputRef.click();
    else {
      setAuthenticated(false);
      setFeedback(["Session timed out"], false);
    }
  };

  const requestAuthentication = async () => {
    return await authenticate(emsNumInput)
      .then(({ ems, region }) => setUserData({ ems, region }))
      .catch((err) => {
        if (err === "504") setFeedback(["No connection to backend", false]);
        else setFeedback([err?.message || "Backend error"], false);
        return false;
      });
  };

  const handleIdConfirmClick = async (event) => {
    event.preventDefault();
    if (await requestAuthentication()) {
      setFeedback(["Success", true]);
      setAuthenticated(true);
    }
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    inputRef.value = "";
    setLoading(true);
    uploadPdfFile(file)
      .then(() => {
        setFeedback(["Successfully uploaded file", true]);
        setLoading(false);
      })
      .catch((err) => {
        if (err === "504") setFeedback(["No connection to backend", false]);
        else setFeedback([err?.message || "Backend error"], false);
        setLoading(false);
      });
  };

  const handleLogout = () => {
    document.cookie = "session=";
    setAuthenticated(false);
    setUserData({
      ems: "",
      region: "",
    });
  };

  return (
    <div className={classes.app}>
      <TopBar title="Landing Page" userData={userData}/>
      <Dialog open={!authenticated}>
        <DialogTitle>Enter EMS-Number</DialogTitle>
        <DialogContent>
          <form onSubmit={handleIdConfirmClick}>
            <TextField
              variant='filled'
              label="EMS-Number"
              type='password'
              name='password'
              onChange={e => setEmsNumInput(e.target.value)}
              value={emsNumInput}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleIdConfirmClick}>Confirm</Button>
        </DialogActions>
      </Dialog>
      <div className={classes.content}>
        <div>
          <Card elevation={4} className={classes.card}>
            <CardHeader title="Upload Stock Excel files">
            </CardHeader>
            <CardContent className={classes.cardContent}>
              <Button
                size='large'
                variant='contained'
                startIcon={<FileUpload />}
                color="primary"
                onClick={startUpload}
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : "Upload"}
              </Button>
              {authenticated && <Button
                startIcon={<Logout />}
                color="primary"
                onClick={handleLogout}
              >
                Logout
              </Button>}
            </CardContent>
          </Card>
        </div>
      </div>
      <input
        accept=".xlsx"
        hidden
        type="file"
        ref={r => (inputRef = r)}
        onChange={handleUpload}
      />
      <Feedback snackbar={feedback} onClose={handleFeedbackClose}/>
    </div>
  );
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
